<template>
    <div class="addCard">
        <div class="cardPut">
            <input type="text" placeholder="输入iccid或sim查询" v-model="iccidOrSim">
            <img src="../../assets/image/myCards/Scan.png" @click="scanHandle" alt="">
        </div>
        <Button class="QueryBtn" @click="Query">查询卡片</Button>
        <div class="addCardItem" v-if="card">
            <img src="../../assets/image/myCards/Card.png" alt="">
            <div class="cardInfo">
                <div>卡号：{{ card.iccid }}</div>
                <div>运营商：{{ operatorsMap[card.operators] }}</div>
            </div>
            <button class="addBtn" @click="addCard">添加</button>
        </div>
    </div>
</template>

<script>
import jssdk from '@/assets/js/jssdk.js'
import { getconf } from '_API_/api.services'
import { getStore } from '@/utils/store'
import { onMounted, getCurrentInstance, toRefs, reactive } from 'vue'
export default {
  setup () {
    const { proxy: ctx } = getCurrentInstance()
    const { $http: http, $tool: tool, $router: router } = ctx
    const state = reactive({
      iccidOrSim: '',
      card: null,
      operatorsMap: {
        1: '中国电信',
        2: '中国移动',
        3: '中国联通',
        4: '聚合卡'
      }
    })
    const scanHandle = () => {
      jssdk.wxScan().then(res => {
        state.iccidOrSim = res.replace('CODE_128,', '')
      })
    }
    const Query = () => {
      if (!state.iccidOrSim) {
        return tool.toast({
          msg: '请输入iccid或sim'
        })
      }
      http.get('/card/mine/queryCard', {
        params: {
          iccidOrSim: state.iccidOrSim
        }
      }).then(res => {
        if (res.data.code !== 0) {
          tool.toast({
            msg: res.data.msg,
            duration: 1500
          })
        }
        state.card = res.data.data
        console.log(res)
      })
    }
    const addCard = () => {
      http.post('/card/mine/add', { openId: getStore({ name: 'userOpenId' })?.openId, cardId: state.card.id }).then(res => {
        if (res.data.code !== 0) {
          tool.toast({
            msg: res.data.msg,
            duration: 1500
          })
        } else {
          tool.toast({
            msg: '添加成功',
            duration: 1500
          })
          router.go(-1)
        }
        console.log(res)
      })
    }
    onMounted(() => {
      getconf({
        url: window.location.href
      }).then(({ data: res }) => {
        if (res.code === 0 && !res.data.code) {
          jssdk.config(res.data)
        }
      })
    })
    return {
      ...toRefs(state),
      scanHandle,
      Query,
      addCard
    }
  }
}
</script>

<style lang="scss" scoped>
.addCard {
    min-height: 100vh;
    min-width: 100vw;
    background: rgba(229, 229, 229, 0.33);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .cardPut {
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(229, 229, 229, 1);
        height: 60px;
        margin: 34px 20px 0;
        display: flex;
        align-items: center;
        padding: 0 20px;

        input {
            flex: 1;
            border: none;
            outline: none;

            &::placeholder {
                font-size: 24px;
                color: rgba(128, 128, 128, 1);
            }
        }
    }

    .QueryBtn {
        width: 535.13px;
        height: 70.95px;
        border-radius: 10.14px;
        background: rgba(42, 130, 228, 1);
        border: none;
        outline: none;
        margin: 40px auto;
        border-radius: 5px;
        background: rgba(42, 130, 228, 1);
        font-size: 24.32px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 35.23px;
        color: rgba(255, 255, 255, 1);
    }

    .addCardItem {
        margin: 0 24px 24px;
        height: 137.84px;
        border-radius: 3.43px;
        background: rgba(255, 255, 255, 1);
        padding: 0 24px;
        display: flex;
        align-items: center;

        img {
            width: 46.62px;
            height: 46.62px;
        }

        .cardInfo {
            margin-left: 28px;
            font-size: 24.32px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 35.23px;
            color: rgba(0, 0, 0, 1);
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        .addBtn {
            border-radius: 10.14px;
            background: rgba(42, 130, 228, 1);
            width: 139.86px;
            height: 52.7px;
            font-size: 24.32px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 35.23px;
            color: rgba(255, 255, 255, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            margin-left: auto;
        }
    }
}
</style>
